/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

import {
  CompletePropertyImprovementItemFragment,
  GetEditPropertyImprovementOnDocumentFormResponseDocument,
} from "../../../../../graphql/generated/types";
import { formatPrice } from "../../../../../utils/formatNumber";
import DetailsSectionColumn from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import {
  PropertyImprovementItemLabels,
  DryerImprovementDataLabels,
  SiloImprovementDataLabels,
  GreenhouseImprovementDataLabels,
} from "../../../../../shared/propertyImprovements/propertyImprovementLabels";
import Row from "../../../../../components/Grid/Row";
import Table from "../../../../../components/Table";
import EditableSection from "../../../../../components/EditableSection";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";

interface Props {
  data: CompletePropertyImprovementItemFragment;
}

const PropertyImprovementData: FC<Props> = ({ data }) => {
  const {
    documentData: { _id: documentId },
    refetch,
  } = useDocumentDetails();
  const mainInfo = useMemo(
    () => [
      [
        {
          label: PropertyImprovementItemLabels.improvementId,
          value: data.improvement.name,
        },
        {
          label: PropertyImprovementItemLabels.propertyId,
          value: data.property.displayName,
        },
        {
          label: PropertyImprovementItemLabels.riskValue,
          value: formatPrice(data.riskValue),
        },
        {
          label: PropertyImprovementItemLabels.isRenewal,
          value: data.isRenewal ? "Sim" : "Não",
        },
      ],
      [
        {
          label: PropertyImprovementItemLabels.hasExtinguishers,
          value: data.hasExtinguishers ? "Sim" : "Não",
        },
        {
          label: PropertyImprovementItemLabels.hasHydrants,
          value: data.hasHydrants ? "Sim" : "Não",
        },
        {
          label: PropertyImprovementItemLabels.hasSmokeDetector,
          value: data.hasSmokeDetector ? "Sim" : "Não",
        },
      ],
    ],
    [data]
  );
  const questionsInfo = useMemo(
    () => [
      {
        label: PropertyImprovementItemLabels.hasGoodsInsurance,
        value: data.hasGoodsInsurance ? "Sim" : "Não",
      },
      {
        label: PropertyImprovementItemLabels.hasFireBrigade,
        value: data.hasFireBrigade ? "Sim" : "Não",
      },
      {
        label: PropertyImprovementItemLabels.hasTheftAlarmSystem,
        value: data.hasTheftAlarmSystem ? "Sim" : "Não",
      },
      {
        label: PropertyImprovementItemLabels.hasUnoccupiedBuilding,
        value: data.hasUnoccupiedBuilding ? "Sim" : "Não",
      },
    ],
    [data]
  );

  const dryerDataColumns = useMemo(() => {
    if (!data.dryerData) return null;
    return [
      [
        {
          label: DryerImprovementDataLabels.brand,
          value: data.dryerData.brand,
        },
      ],
    ];
  }, [data.dryerData]);

  const dryerQuestionsColumns = useMemo(() => {
    if (!data.dryerData) return [];
    return [
      {
        label: DryerImprovementDataLabels.capacity,
        value: data.dryerData.capacity,
      },
      {
        label: DryerImprovementDataLabels.hasThermometry,
        value: data.dryerData.hasThermometry ? "Sim" : "Não",
      },
      {
        label: DryerImprovementDataLabels.isThermometryAutomated,
        value: data.dryerData.isThermometryAutomated ? "Sim" : "Não",
      },
      {
        label: DryerImprovementDataLabels.hasTrainedOperator,
        value: data.dryerData.hasTrainedOperator ? "Sim" : "Não",
      },
      {
        label: DryerImprovementDataLabels.hasControlPanelWithMemory,
        value: data.dryerData.hasControlPanelWithMemory ? "Sim" : "Não",
      },
      {
        label: DryerImprovementDataLabels.hasRoastingAndDryingProcess,
        value: data.dryerData.hasRoastingAndDryingProcess ? "Sim" : "Não",
      },
      {
        label: DryerImprovementDataLabels.hasGrainProcessingSector,
        value: data.dryerData.hasGrainProcessingSector ? "Sim" : "Não",
      },
    ];
  }, [data.dryerData]);

  const siloDataColumns = useMemo(() => {
    if (!data.siloData) return null;
    return [
      {
        label: SiloImprovementDataLabels.hasThermometry,
        value: data.siloData.hasThermometry ? "Sim" : "Não",
      },
      {
        label: SiloImprovementDataLabels.isThermometryAutomated,
        value: data.siloData.isThermometryAutomated ? "Sim" : "Não",
      },
    ];
  }, [data.siloData]);

  return (
    <EditableSection
      title="Dados da Benfeitoria"
      footerButtons={[]}
      main
      editQuery={GetEditPropertyImprovementOnDocumentFormResponseDocument}
      objectId={data._id}
      editPayload={{ documentId }}
      useV2
      onSubmit={refetch}
    >
      <DetailsSectionColumns columns={mainInfo} />
      <Row>
        <DetailsSectionColumn rows={questionsInfo} colSpan={[12, 12]} />
      </Row>
      {dryerDataColumns && (
        <DetailsSection
          title={PropertyImprovementItemLabels.dryerData}
          subtitle
        >
          <DetailsSectionColumns columns={dryerDataColumns} />
          <Row>
            <DetailsSectionColumn
              rows={dryerQuestionsColumns}
              colSpan={[12, 12]}
            />
          </Row>
        </DetailsSection>
      )}
      {siloDataColumns && (
        <DetailsSection title={PropertyImprovementItemLabels.siloData} subtitle>
          <Row>
            <DetailsSectionColumn rows={siloDataColumns} colSpan={[12, 12]} />
          </Row>
        </DetailsSection>
      )}
      {data.greenhouseData && !!data.greenhouseData.length && (
        <Table
          data={data.greenhouseData}
          columns={[
            {
              label: GreenhouseImprovementDataLabels.age,
              key: "age",
            },
            {
              label: GreenhouseImprovementDataLabels.cultureId,
              key: "culture",
              special: "many2one",
            },
            {
              label: GreenhouseImprovementDataLabels.structureId,
              key: "structure",
              special: "many2one",
            },
            {
              label: GreenhouseImprovementDataLabels.referenceValue,
              key: "referenceValue",
            },
            {
              label: GreenhouseImprovementDataLabels.shouldHireContent,
              key: "shouldHireContent",
              special: "boolean",
            },
            {
              label: GreenhouseImprovementDataLabels.area,
              key: "area",
            },
            {
              label: GreenhouseImprovementDataLabels.contentReferenceValue,
              key: "contentReferenceValue",
            },
          ]}
        />
      )}
    </EditableSection>
  );
};

export default PropertyImprovementData;
